import request from "../plugins/request";

/**
 * 用户登录
 */
export const login = (data) => {
  return request({
    url: "/sys/user/login",
    method: "post",
    data,
  });
};

/**
 * 用户修改密码
 */
export const userChangePassword = (data) => {
  return request({
    url: "/sys/user/password/change",
    method: "post",
    data,
  });
};

/**
 * 获取当前管理员权限
 */
export function userAuth() {
  return request({
    url: "/sys/user/auth",
    method: "get",
  });
}

/**
 * 获取用户列表
 */
export const userList = (data) => {
  return request({
    url: "/sys/user/list",
    method: "post",
    data,
  });
};

/**
 * 添加用户
 */
export const addUser = (data) => {
  return request({
    url: "/sys/user/add",
    method: "post",
    data,
  });
};

/**
 * 重置用户密码
 */
export const resetUser = (data) => {
  return request({
    url: "/sys/user/reset",
    method: "post",
    data,
  });
};
