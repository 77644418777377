import { createRouter, createWebHashHistory } from "vue-router";
import { isLogin } from "@/utils/auth";
import Layout from "@/layout/IndexView.vue";

const routes = [
  {
    path: "/login",
    name: "LoginView",
    component: () => import("@/views/user/LoginView.vue"),
  },
  {
    path: "/",
    name: "index",
    component: Layout,
    redirect: "/home",
    meta: { title: "首页" },
    children: [
      {
        path: "home",
        name: "HomeView",
        component: () => import("@/views/HomeView.vue"),
        meta: {
          title: "工作台",
          auth: true,
          menuId: 2,
          parentId: 1,
        },
      },
    ],
  },
  {
    path: "/super",
    name: "super",
    component: Layout,
    meta: { title: "超管" },
    children: [
      {
        path: "tenant",
        name: "TenantView",
        component: () => import("@/views/super/TenantView"),
        meta: {
          title: "租户管理",
          auth: true,
          menuId: 11,
          parentId: 10,
        },
      },
      {
        path: "user",
        name: "UserView",
        component: () => import("@/views/super/UserView"),
        meta: {
          title: "用户管理",
          auth: true,
          menuId: 12,
          parentId: 10,
        },
      },
    ],
  },
  {
    path: "/article",
    name: "article",
    component: Layout,
    meta: { title: "文章" },
    children: [
      {
        path: "list",
        name: "ArticleList",
        component: () => import("@/views/article/ArticleList"),
        meta: {
          title: "文章管理",
          auth: true,
          menuId: 21,
          parentId: 20,
        },
      },
      {
        path: "category",
        name: "ArticleCategory",
        component: () => import("@/views/article/ArticleCategory"),
        meta: {
          title: "文章分类",
          auth: true,
          menuId: 22,
          parentId: 20,
        },
      },
    ],
  },
  {
    path: "/product",
    name: "product",
    component: Layout,
    meta: { title: "产品" },
    children: [
      {
        path: "list",
        name: "ProductList",
        component: () => import("@/views/product/ProductList"),
        meta: {
          title: "产品管理",
          auth: true,
          menuId: 31,
          parentId: 30,
        },
      },
      {
        path: "category",
        name: "ProductCategory",
        component: () => import("@/views/product/ProductCategory"),
        meta: {
          title: "产品分类",
          auth: true,
          menuId: 32,
          parentId: 30,
        },
      },
    ],
  },
  {
    path: "/team",
    name: "team",
    component: Layout,
    meta: { title: "团队" },
    children: [
      {
        path: "list",
        name: "TeamView",
        component: () => import("@/views/team/TeamView"),
        meta: {
          title: "团队管理",
          auth: true,
          menuId: 41,
          parentId: 40,
        },
      },
    ],
  },
  {
    path: "/page",
    name: "page",
    component: Layout,
    meta: { title: "页面" },
    children: [
      {
        path: "list",
        name: "PageView",
        component: () => import("@/views/page/PageView"),
        meta: {
          title: "页面管理",
          auth: true,
          menuId: 51,
          parentId: 50,
        },
      },
    ],
  },
  {
    path: "/business",
    name: "business",
    component: Layout,
    meta: { title: "业务" },
    children: [
      {
        path: "list",
        name: "BusinessView",
        component: () => import("@/views/business/BusinessView"),
        meta: {
          title: "业务范围",
          auth: true,
          menuId: 61,
          parentId: 60,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// 路由拦截
router.beforeEach((to, from, next) => {
  if (to.fullPath === "/login" && isLogin()) {
    next("/");
  } else if (to.meta.auth && !isLogin()) {
    next("/login");
  } else {
    next();
  }
});

export default router;
