import axios from "axios";
import { message } from "ant-design-vue";
import { isLogin, getAccessToken, clearAccessToken } from "@/utils/auth";
import router from "@/router";

const service = axios.create({
  // baseURL: "http://localhost:8080",
  baseURL: "https://api2.muzimuzi.com/",
  timeout: 20000,
});

// 请求拦截
service.interceptors.request.use(
  (config) => {
    if (isLogin()) {
      config.headers["token"] = getAccessToken();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code === "510" || res.code === "509") {
      clearAccessToken();
      router.replace("/login");
      return service;
    }
    if (res.code !== "0" && res.code !== "510") {
      if (res.code === "520") {
        const errorArr = Object.values(res.data);
        console.log(errorArr);
        errorArr.forEach((error) => {
          message.warning(error);
        });
      } else {
        message.warning(res.msg);
      }
      return Promise.reject(new Error(res.msg || "系统错误"));
    }
    return response && response.data;
  },
  (error) => {
    console.log("err" + error); // for debug
    message.warning(error.message);
    return Promise.reject(error);
  }
);

export default service;
